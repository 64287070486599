import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction, Action } from 'src/utils/reduxUtils'
import {
  RecollectionFetchDriver,
  RecollectionSignInPhone,
  RecollectionSignInEnterCode,
  RecollectionSignInResendCode,
  RecollectionSetAuthToken,
  RecollectionLogout,
  RecollectionSetDriver,
} from 'src/constants/actionTypes'
import { ApiService, callApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'
import { authService } from 'src/services/AuthService'

interface SignInPhoneRequest {
  partnerName: string
  phone: string
}

function* onSignInPhone(action: AsyncAction<SignInPhoneRequest>) {
  const { partnerName, phone } = action.payload

  try {
    const { payload }: { payload: { smsSend: boolean } } = yield call(
      callApi,
      Endpoints.RecollectionSignInPhone,
      {
        method: 'POST',
        body: {
          phone,
          partnerName,
        },
        apiService: ApiService.Paraworks,
      },
    )

    if (payload?.smsSend === false) {
      throw {
        payload: {
          message: 'No partner driver found with the provided phone number',
        },
      }
    }

    yield put(RecollectionSignInPhone.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to sign in'

    yield put(RecollectionSignInPhone.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface SignInEnterCodeRequest {
  code: string
  partnerName: string
  phone: string
}

interface SignInEnterCodePayload {
  token: string
}

function* onSignInEnterCode(action: AsyncAction<SignInEnterCodeRequest>) {
  const { partnerName, code, phone } = action.payload

  try {
    const { payload }: { payload: SignInEnterCodePayload } = yield call(
      callApi,
      Endpoints.RecollectionSignInEnterCode,
      {
        method: 'POST',
        body: {
          code,
          partnerName,
          phone,
        },
        apiService: ApiService.Paraworks,
      },
    )

    yield put(RecollectionSignInEnterCode.success())
    yield put(RecollectionSetAuthToken.request(payload.token))
    yield put(RecollectionFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to sign in'

    yield put(RecollectionSignInPhone.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

function* onSetDriverToken(action: Action<string>) {
  const authToken = action.payload

  yield put(RecollectionSetAuthToken.success(authToken))

  authService.setStoredRecollectionAuthToken(authToken)
}

function* onLogout() {
  yield put(RecollectionSetDriver.request(null))
  yield put(RecollectionSetAuthToken.request(null))
  yield put(RecollectionLogout.success())
}

export function* recollectionAuthSaga() {
  yield takeEvery(
    [RecollectionSignInPhone.type.REQUEST, RecollectionSignInResendCode.type.REQUEST],
    onSignInPhone,
  )
  yield takeEvery(RecollectionSignInEnterCode.type.REQUEST, onSignInEnterCode)
  yield takeEvery(RecollectionSetAuthToken.type.REQUEST, onSetDriverToken)
  yield takeEvery(RecollectionLogout.type.REQUEST, onLogout)
}
