import { Formik } from 'formik'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  ContractorPortalFetchPartners,
  ContractorPortalSignInPhone,
  OnboardingSignInPhone,
  RecollectionSignInPhone,
} from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { SignInFormValues } from './SignInForm.interface'
import { SignInSchema } from './SignInForm.validator'
import { FormikTextField } from 'src/components/shared/form/formik/FormikTextField'
import { FormErrorMessage } from 'src/components/shared/form/FormErrorMessage'
import tw from 'twin.macro'
import { LoadingButton } from '@mui/lab'
import parsePhoneNumber from 'libphonenumber-js'
import { TOS } from 'src/components/shared/TOS'
import { handleApiError } from 'src/utils/errorHandlers'

interface Props {
  isContinue?: boolean
  partnerName?: string
  platform: 'recollection' | 'onboarding' | 'contractorPortal'
  onSuccess(phone: string): void
}

const initialValues: SignInFormValues = {
  phone: '',
}

const PHONE_MASK = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

export const SignInForm = ({ isContinue, partnerName, platform, onSuccess }: Props) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const handleFormSubmit = async (values: SignInFormValues) => {
    setIsLoading(true)
    setError(null)

    try {
      const parsedPhone = parsePhoneNumber(values.phone, 'US')

      const phone = parsedPhone?.formatInternational().replace(/\s+/g, '') || values.phone

      if (platform === 'recollection') {
        await createAsyncAction(dispatch, RecollectionSignInPhone.request({ phone, partnerName }))
      }
      if (platform === 'onboarding') {
        await createAsyncAction(dispatch, OnboardingSignInPhone.request({ phone, partnerName }))
      }
      if (platform === 'contractorPortal') {
        await createAsyncAction(dispatch, ContractorPortalSignInPhone.request({ phone }))
        await createAsyncAction(dispatch, ContractorPortalFetchPartners.request({ phone }))
      }

      onSuccess(phone)
    } catch (err: any) {
      handleApiError(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={SignInSchema}
    >
      {({ handleSubmit }) => (
        <div>
          <FormikTextField
            name="phone"
            css={tw`w-full mb-6`}
            mask={PHONE_MASK}
            placeholder="Enter your phone number"
          />
          <TOS css={tw`mb-6`} />
          <FormErrorMessage css={tw`mb-4`} error={error} />
          <LoadingButton
            css={tw`w-full`}
            variant="contained"
            loading={isLoading}
            onClick={() => handleSubmit()}
          >
            <span>{`${isContinue ? 'Continue' : 'Start'} ${
              platform === 'onboarding' ? 'onboarding' : ''
            }`}</span>
          </LoadingButton>
        </div>
      )}
    </Formik>
  )
}
